@import "../../styles/base.scss";

.loginPage_header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4389fa;

  .loginPage_header_inner {
    max-width: 1420px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 30px;

    .unnlockIcon {
      width: 10%;
    }
  }
}

.register-container {
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  // height: 93vh;

  .registrationScreen {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 20px;
    // height: 94vh;
    // overflow: auto;

    .register_form_container {
      // background: linear-gradient(0deg, #ffffff, #ffffff), rgba(7, 7, 7, 0.1);
      border-radius: 24px;
      padding: 45px 0px;
      // box-shadow: 0 8px 32px 0 #e3eeff;
      border: 1px solid #dee5ff;
      background-color: white;
      width: 450px;

      // height: 500px;
      .register_title_txt {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
      }

      .register_form_container_outer {
        display: flex;
        width: 100%;
        justify-content: center;

        .register_form_container_inner {
          width: 85%;

          .newUserTitle {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0px;
            text-align: center;
          }

          .socialLoginsTitle {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            text-align: center;
          }

          .error_fields {
            color: #ff4d4f;
            height: 20px;
          }

          .circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 8px;
            background: #746e8c;
          }

          .passtxt {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.01em;
            font-feature-settings: "tnum" on, "lnum" on;
            color: #ff4d4f;
            margin: 0;
            margin-right: 4px;
          }

          .passtxtgreen {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 11px;
            line-height: 14px;
            letter-spacing: 0.01em;
            font-feature-settings: "tnum" on, "lnum" on;
            color: #6ebf67;
            margin: 0;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .loginScreen {
    display: flex;
    width: 100%;
    // height: 94vh;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 5% 17%;

    .left_side {
      width: 43%;
      min-width: 250px;
    }

    .right_side {
      border: 1px solid #dee5ff;
      border-radius: 24px;
      padding: 30px;
      text-align: center;
      width: 380px;
      min-width: 250px;
      background: white;
      min-height: 450px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .newUserTitle {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 10px;
        text-align: center;
      }

      .signUpTitle {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0px;
        text-align: center;
      }

      .to {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0px;
        text-align: center;
      }

      .unnllockYurPotential {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
      }

      .description {
        border: 1px solid #dee5ff;
        border-radius: 8px;
        padding: 20px;
        margin-top: 30px;
      }
    }
  }

  .register_now_btn {
    height: 48px;
    cursor: pointer;
    background: rgb(67, 137, 250);
    border-radius: 8px;
    color: rgb(255, 255, 255);
    border: none;
    font-weight: 600;
    width: 150px;
    width: 100%;
  }

  .devider_custom {
    border: 1px solid #dee5ff;
    border-left: none;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      background: white;
      padding: 4px;
      margin-top: 10px;
    }
  }

  .social-section {
    background: $inputFieldBackground;
    border-radius: 8px;
    padding: 20px;
  }

  .footer {
    background: $primaryColor;
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

.upload-container {
  .uploadContainer-inner {
    padding: 0px 50px 50px;
  }

  .title_conatiner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      font-size: 1.25rem;
      color: $primaryFontColor;
      line-height: 30px;
      font-weight: 500;
      letter-spacing: 0.01em;
    }
  }

  .bottomtext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #606060;
    margin-bottom: 0;
  }

  .uploadProgressBar {
    // margin-bottom: -30px;
    padding: 0px 20px;
  }

  .finalregistertitle {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #261c4b;
  }

  .uploaddiv {
    max-width: 100%;
    padding: 45px 76px;
    margin: 30px 0px;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // text-align: center;
    cursor: pointer;
    // align-items: "flex-start";
    text-align: "left";
    // margin-top: 80px;
    .circularCards {
      display: flex;
      align-items: flex-end;
      justify-content: space-around;
      width: 100%;
      margin-top: 40px;
    }

    .circularCards-mobile {
      display: none;
    }

    .circlebg {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 74px;
      background: #cfeee6;
      border-radius: 50%;
    }

    .ring {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 90px;
      height: 90px;
      background: transparent;
      border-radius: 50%;
      text-align: center;
      line-height: 150px;
      font-family: sans-serif;
      font-size: 20px;
      letter-spacing: 4px;
      text-transform: uppercase;
    }

    .ring:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border: 3px solid transparent;
      border-top: 3px solid #00805f;
      border-right: 3px solid #00805f;
      border-radius: 50%;
      animation: animateC 3s linear infinite;
    }

    @keyframes animateC {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    @keyframes animate {
      0% {
        transform: rotate(45deg);
      }

      100% {
        transform: rotate(405deg);
      }
    }

    .statusTitletx {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #261c4b;
      margin-top: 40px;
      margin-bottom: -140px;
      padding: 0px 10px;
    }

    .statusbardiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      background: #ffffff;
      opacity: 0.72;
      border: 1px solid rgba(38, 28, 75, 0.2);
      border-radius: 8px;
      margin-top: 200px;
    }

    .atsTitletxt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #4389fa;
      margin-bottom: 0;
      margin-left: 16px;
    }

    .atsSubtxt {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: rgba(38, 28, 75, 0.8);
      margin-bottom: 0;
      margin-left: 32px;
      margin-top: 8px;
    }

    .extractTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #261c4b;
      margin-bottom: 40px;
    }

    .greycircle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96px;
      height: 96px;
      background: #ecf3ff;
      border-radius: 100px;
    }

    .smallLine {
      width: 80px;
      border-bottom: 1px solid #d5e0f3;
      margin-left: 16px;
      margin-right: 16px;
    }

    .dragtext {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #606060;
      margin-top: 36px;
      margin-bottom: 8px;
      margin-left: 32px;
    }

    .check_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin-bottom: 40px;
      background: #4389fa;
      border-radius: 10px;
      padding-left: 26px;
      padding-right: 26px;
      border: none;
      box-shadow: none;
      cursor: pointer;

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #fefefe;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 8px;
      }
    }

    .filetext {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.01em;
      color: rgba(96, 96, 96, 0.55);
    }

    .welcomeTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      .nxtButton {
        cursor: pointer;
      }
    }
  }

  .sc-bczRLJ {
    max-width: 100%;
    padding: 76px;
    margin-bottom: 20px;

    svg {
      margin-right: 15px;
    }

    .fVfcRr > span {
      font-size: 16px;
    }

    .file-types {
      display: contents;
    }
  }

  .kiHXWb {
    border: none;
  }

  // .eWRUdH {
  //   display: none;
  // }
  .sc-gsnTZi {
    width: 68vw;
    margin-top: 95px;
    height: 500px;
  }

  .input_upload {
    background: white;
    border: 1px solid $inputFieldBorder;
    box-sizing: border-box;
    border-radius: 8px;
    height: 60px;
    display: flex;
    align-items: center;

    .ant-input-group .ant-input {
      margin-top: 12px;
      color: $primaryFontColor;
    }
  }

  .ant-input-group-addon {
    background: transparent;
    border: none;
    padding: 0;
    padding-right: 1px;

    .browseBtn {
      border: none;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      height: 56px;
      background: $primaryColor;
      font-weight: 600;
      font-size: 14px;
      color: white;
      min-width: 100px;
      cursor: pointer;

      &:hover,
      &:focus {
        background: $primaryColor;
      }
    }
  }
}

.registerlastdiv {
  display: flex;
  align-items: center;

  .innercard {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 10px;
    margin-bottom: 55px;

    img {
      width: 35px;
    }

    .title {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #261c4b;
      margin-bottom: 4px;
    }

    .subtitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: rgba(38, 28, 75, 0.8);
      margin: 0px;
      max-width: 288px;
    }
  }
}

@media only screen and (max-width: 800px) {
  .loginPage_header {
    .loginPage_header_inner {
      padding: 22px 24px;

      .unnlockIcon {
        width: 20%;
      }
    }
  }

  .register-container {
    .loginScreen {
      padding: 40px 10%;
      // justify-content: initial;

      .left_side {
        width: 43%;
        margin-bottom: 30px;
      }

      .right_side {
        margin-bottom: 30px;
      }
    }

    .registrationScreen {
      .register_form_container {
        width: 350px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .loginPage_header {
    .loginPage_header_inner {
      padding: 22px 24px;

      .unnlockIcon {
        width: 30% !important;
      }
    }
  }
}


@media only screen and (max-width: 650px) {
  .upload-container {
    .uploadContainer-inner {
      padding: 0px 0px 50px;
      max-width: 100%;
      flex: 0 0 90%;
    }
  }
}

@media only screen and (max-width: 850px) {
  .upload-container {
    .title_conatiner {
      h2 {
        font-size: 1.15rem;
      }
    }

    .uploaddiv {
      padding: 24px 20px;
      margin-top: 1.5rem;
      .circlebg {
        width: 43px;
        height: 43px;
      }

      .ring {
        width: 50px;
        height: 50px;
      }

      .statusTitletx {
        font-size: 14px;
      }

      .atsTitletxt {
        font-size: 14px;
      }
    }
  }
  .register-container {
    .loginScreen {
      padding: 40px 10px;

      .left_side {
        width: 100%;
        margin-bottom: 30px;
      }

      // .right_side {
      //   margin-bottom: 30px;
      // }
    }

    // .registrationScreen {
    //   .register_form_container {
    //     width: 350px;
    //   }
    // }
  }
}

@media only screen and (max-width: 380px) {
  .upload-container {
    .uploaddiv {
      .circularCards {
        display: none;
      }

      .circularCards-mobile {
        display: block;

        .top-layer,
        .bottom-layer {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }

        .bottom-layer {
          margin-top: 65%;
        }
      }
      .nxtButton {
        position: unset;
      }
    }
  }
}
