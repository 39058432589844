@import "../../styles/base.scss";

.leftNav-container {
  background-color: white;
  border-radius: 16px;
  // box-shadow: 0 8px 32px 0 #e3eeff;
  margin-bottom: 56px;
  min-height: 60px;
  padding: 24px;

  .filteresCount {
    display: flex;
    align-items: center;
    padding: 13px 0px;
    font-size: 18px;
    //border-bottom: 4px solid rgb(233, 241, 255);
    .Filters {
      font-weight: 600;
    }
    .reset {
      color: #4389fa;
      font-weight: 600;
      cursor: pointer;
      margin-left: 20px;
    }
  }

  .main-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: $primaryFontColor;
    margin-left: 16px;
  }

  .clearFiltertxt {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $primaryColor;
  }

  .filter-titles {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: $primaryFontColor;
    margin-top: 30px;
    margin-bottom: 5px;
    padding-bottom: 6px;
  }
  .filter_body {
    height: 150px;
    overflow: auto;
    margin: 15px 0px;
  }
  .custom_check_grp.ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
  .custom_check_grp.ant-radio-group {
    display: flex;
    flex-direction: column;
  }
  .companylocation_container {
    padding-top: 15px;
    .custom_company_location_selector {
      .ant-select-selector {
        border: 1px solid rgba(67, 137, 250, 0.4);
        border-radius: 8px;
        background: #f9fcff;
        padding: 7px;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .leftNav-container {
    border-radius: 0%;
    margin-top: -1px;
    padding: 0px 20px;
  }
}
