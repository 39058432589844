@import "../../styles/base.scss";

.loginPage_header {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4389fa;

  .loginPage_header_inner {
    max-width: 1420px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 30px;

    .unnlockIcon {
      width: 10%;
    }
  }
}

.login-container {
  // width: 100vw;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .signUpBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    button {
      border: 1px solid #4389fa;
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      padding: 10px 25px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      letter-spacing: 0.03em;
      cursor: pointer;
      color: #4389fa;
    }
  }
  .person-img_login {
    position: absolute;
    bottom: 0;
    right: 13%;
    z-index: 1;
    width: 25%;
    height: auto;
  }
  .footer_login {
    background: $primaryColor;
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .right_side {
    width: 100%;
    .tryFree_button {
      border: 1px solid #4389fa;
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      padding: 10px 25px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: 47%;
      letter-spacing: 0.03em;
      cursor: pointer;
      color: #4389fa;
    }
    .subscrption_btn {
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      padding: 10px 25px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: 47%;
      letter-spacing: 0.03em;
      cursor: pointer;
      color: #4389fa;
      border: none;
    }
  }

  .login-section {
    // background: linear-gradient(0deg, #ffffff, #ffffff), rgba(7, 7, 7, 0.1);
    border-radius: 24px;
    padding: 30px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    width: 100%;
    border: 1px solid #dee5ff;
    background-color: white;
    width: 380px;
    margin: 18px;
    // height: 450px;

    .error_fields {
      color: #ff4d4f;
      height: 22px;
    }
    .hint-text {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 200%;
      color: $primaryFontColor;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        margin: 0%;
      }
    }
    .login_btn {
      height: 48px;
      cursor: pointer;
      background: rgb(67, 137, 250);
      border-radius: 8px;
      color: rgb(255, 255, 255);
      border: none;
      font-weight: 600;
      width: 150px;
    }
    .forgotpass_btn {
      font-weight: 600;
      color: rgb(67, 137, 250);
      background: transparent;
      border: none;
      width: 47%;
      cursor: pointer;
    }
  }
}
