.textOnInput {
  position: relative;
  width: 100%;
  .label {
    font-weight: 500;
    font-size: 12px;
    color: #261c4b;
    background: linear-gradient(0deg, #ffffff, #ffffff), rgba(7, 7, 7, 0.1);
    padding: 0px 5px;
    position: absolute;
    top: -20%;
    left: 15px;
    z-index: 2;
  }
  .input_custom {
    border: 1px solid #DEE5FF;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    &:focus {
      outline: none;
    }
    &.haserror {
      border: 1px solid red;
    }
  }
  input:disabled {
    background-color: transparent;
  }
  .error_text {
    margin: 0;
    font-size: 12px;
    color: red;
    text-align: left;
  }
  .password_input_custom.ant-input-affix-wrapper {
    border: 1px solid #261c4b;
    border-radius: 8px;
    padding: 0px;
    padding: 0px 10px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .Custome_select.ant-select {
    width: 100%;
    padding: 2px;
    border: 1px solid #dee5ff;
    border-radius: 8px;
    box-sizing: border-box;
    // height: 50px;
    // padding-left: 15px;
    .ant-select-selection.ant-select-selection--single {
      height: 53px;
    }
    .ant-select-selector {
      border: none;
      font-size: 14px;
    }
  }
  .inputwithicon_custom {
    border: 1px solid #261c4b;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  .icon_holder {
    position: absolute;
    top: 0%;
    right: 2%;
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
