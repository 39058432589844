.tipsFAQsTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.01em;
  font-feature-settings: "tnum" on, "lnum" on;
  color: #4389fa;
  margin-top: 16px;
  margin-bottom: 24px;
  background-color: white;
  border-radius: 16px;
  padding: 12px;
  width: 100%;
}

.cardSection {
  background: #ffffff;
  // box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #000000;
  }

  .desc {
    width: 100%;
    background: rgba(67, 137, 250, 0.1);
    margin-top: 16px;
    padding: 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #000000;
  }
}

.error_fields {
  color: #ff4d4f;
  height: 22px;
}
