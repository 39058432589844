@import "../../styles/base.scss";

.terms_Blog {
  display: block;
  width: 102%;
  height: auto;
  margin: auto;
  background: #ffffff;
  border-radius: 24px;
  .policies_Header {
    padding: 3%;
    height: 60px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 60px;
    color: #4389fa;
    margin: 4%;
  }
  .policies_Header:hover {
    text-decoration: underline;
  }
  .blog_policy {
    .policy_Content {
      padding-left: 20%;
      padding-right: 20%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.01em;
      color: #261c4b;
    }
    .content_Head {
      padding-left: 20%;
      letter-spacing: 0.01em;
      color: #261c4b;
    }
    .content_italic {
      padding-left: 20%;
      font-size: 14px;
      line-height: 170%;
      letter-spacing: 0.01em;
      color: #261c4b;
    }
  }
}
