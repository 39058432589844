.evaluateJob_container {
  color: #261c4b;
  width: 90%;
  margin: auto;
  .form_conatiner {
    width: 100%;
    background: #ffffff;
    border-radius: 24px;
    padding: 30px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    .header_conatiner {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      .text_holder {
        h4 {
          font-weight: 600;
          font-size: 18px;
        }
        p {
          font-weight: normal;
          font-size: 14px;
          line-height: 0;
        }
      }
      .search_icon {
        margin: 20px 20px 20px 0px;
      }
      .form_img {
        position: absolute;
        top: -208%;
        right: 0;
      }
    }
    .sub_title {
      font-weight: 600;
      font-size: 14px;
      margin: 30px 0px;
    }
    .form_wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .input_holder {
        width: 49%;
        padding: 15px 0px;
      }
    }
    .custom_btn {
      width: 150px;
      height: 40px;
      margin: 10px 0px;
    }
  }

  .table_container {
    width: 100%;
    background: #ffffff;
    border-radius: 24px;
    padding: 30px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    margin-top: 50px;
    .evalTable_container {
      width: 100%;
      .table {
        width: 100%;
        .table_header_row {
          width: 100%;
          .col_header {
            font-weight: 500;
            font-size: 14px;
            padding: 10px;
            .col_header_txt {
              background: #fafcff;
              border-radius: 8px;
              padding: 10px;
              text-align: left;
            }
          }
        }
        .table_header_col {
          width: 100%;
          .row_header {
            width: 15%;
            font-weight: 500;
            font-size: 14px;
            padding: 10px;
            .row_header_txt {
              background: #fafcff;
              border-radius: 8px;
              padding: 10px;
              text-align: left;
            }
          }
          .row_data {
            font-weight: 500;
            font-size: 14px;
            padding: 10px;
            .row_txt {
              padding: 10px;
              text-align: left;
              background: #ffffff;
              border: 1px solid #dee5ff;
              border-radius: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
