.update_education_container {
  .title {
    font-weight: 600;
    font-size: 20px;
    color: #261c4b;
  }
  .add_btn {
    background: rgba(67, 137, 250, 0.1);
    border-radius: 10px;
    color: #4389fa;
    font-weight: 500;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
  .save-button {
    font-size: 16px;
    color: #4389fa;
    background: rgba(67, 137, 250, 0.1);
    border-radius: 10px;
    border: none;
    padding: 7px 12px;
    cursor: pointer;
    margin: 0px 10px;
  }
}
