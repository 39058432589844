$phone: "(max-width: 360px)";
$largePhone: "(max-width:600px)";
$tablet: "(min-width:600px) and  (max-width:900px)";
$desktop: "(max-width:1320px)";
$LGdesktop: "(max-width:1600px)";
$MinWidth: "(min-width:600px)";

.explore_conatiner {
  width: 100%;
  height: 100%;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
    // height: 48px;
    // padding-top: 10px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: none;
    box-shadow: none;
  }

  .Exploretitle {
    background-color: white;
    border-radius: 16px;
    margin-bottom: 14px;
    padding: 12px;
    font-size: 20px;
    font-weight: 600;
    color: #4389fa;
  }

  .explore_comp {
    padding: 30px;

    .exploreCompHeader {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 32px;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #261c4b;
    }

    .exploreComp_container {
      display: flex;
      justify-content: space-between;
    }

    .card_conatiner {
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      //padding: 20px 50px;
      width: 70.83%;
      position: relative;

      @media #{$tablet} {
        width: 100%;
        padding: 1rem;
        padding: 1rem;
      }

      .filteres {
        margin-bottom: 20px;
        display: flex;
        padding: 16px;
        flex-wrap: wrap;
        border-bottom: 4px solid rgb(233, 241, 255);
        justify-content: space-between;

        // width:33%;
        .filters-radiobutton {
          display: grid;
          grid-template-columns: auto auto;
          gap: 1.5rem;
          justify-content: flex-start;
        }
      }

      .CompanyDescCard {
        background: #ffffff;
        // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
        padding: 25px 0;
        width: 100%;
        margin-top: 30px;

        .card_header {
          .left {
            display: flex;

            img {
              margin-right: 10px;
              width: 50px;
              height: auto;
            }

            .companyName {
              color: #261c4b;
              font-weight: 500;
              font-size: 20px;
            }

            .location {
              color: #606060;
              font-weight: 400;
              font-size: 16px;
            }
          }
        }

        .descriptions {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
        }
      }

      .ByCompanyName_submit {
        position: absolute;
        top: 6%;
        right: 3%;

        button {
          font-weight: 500;
          font-size: 16px;
          color: white;
          background: #4389fa;
          border-radius: 10px;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
        }
      }

      .suggestedSkills,
      .selecteddSkills {
        color: #000000;
        margin: 24px;

        .title {
          font-weight: 600;
          font-size: 14px;
          margin: 15px 0px;
          color: #000000;
          font-feature-settings: "tnum" on, "lnum" on;
          letter-spacing: 0.011em;
        }

        .skills {
          // background: #f9fcff;
          border: 1px solid rgba(67, 137, 250, 0.4);
          border-radius: 8px;
          padding: 10px;
          display: flex;
          flex-wrap: wrap;
          // max-height: 200px;
          overflow: auto;
          //margin-top: 30px;

          .skill {
            padding: 5px 10px;
            background: #ffffff;
            border: 1px solid lightgray;
            border-radius: 8px;
            margin: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;

            p {
              margin-bottom: 0px;
              font-size: 12px;
              color: #261c4b;
              font-weight: 500;
              padding: 8px;
            }

            span {
              font-size: 22px;
              padding-left: 10px;
            }
          }
        }

        .View-button {
          font-size: 16px;
          color: #4389fa;
          background: rgba(67, 137, 250, 0.1);
          border-radius: 10px;
          border: none;
          padding: 7px 12px !important;
          cursor: pointer;
          margin: 10px;
        }
      }

      .selecteddSkills {
        .skills {
          .skill {
            padding-bottom: 0px;
          }

          span {
            transform: rotate(45deg);
            margin-bottom: 7px;
          }
        }
      }

      .customeselectResume {
        display: flex;
        align-items: center;
        width: 50%;
        border: 1px solid rgb(222, 229, 255);
        border-radius: 8px;
        height: 50px;
        color: white;

        .ant-select-arrow {
          color: rgba(0, 0, 0, 0.25);
        }

        .ant-select-selection-item {
          color: black;
        }
      }

      .inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .exploreComp-whycontainer {
      width: 25%;

      @media #{$tablet} {
        width: 100%;
      }
    }
  }
}

@media #{$largePhone} {
  .explore_conatiner {
    .explore_comp {
      padding: 1.5rem;

      .exploreComp_container {
        flex-direction: column;
        gap: 1rem 0;
        align-items: center;
      }

      .card_conatiner {
        width: 100%;

        .suggestedSkills {
          .title {
            font-size: 16px;
            font-weight: 500;
            margin: 12px 0;
          }
        }

        .filteres {
          .filters-radiobutton {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 16px;
          }
        }
      }

      .exploreComp-whycontainer {
        width: 100%;
      }
    }
  }
}

@media screen and #{$tablet} {
  .explore_conatiner {
    .explore_comp {
      .exploreComp_container {
        flex-direction: column;
        align-items: center;
        gap: 1rem 0;
      }

      .card_conatiner {
        width: 100%;

        .suggestedSkills {
          .title {
            font-size: 16px;
            font-weight: 500;
          }
        }

        .card_conatiner {
          .CompanyDescCard {
            margin-top: 1rem;
          }
        }
      }

      .exploreComp-whycontainer {
        width: 100%;
      }
    }
  }
}
