.match_container {
  background: #ffffff;
  border: 1px solid #dee5ff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  width: 32%;
  .title_container {
    display: flex;
    align-items: center;
    .title {
      font-weight: 600;
      font-size: 20px;
      margin-right: 5px;
    }
  }
  .image_tv {
    width: 100%;
    margin-top: 20px;
  }
  .blue_container {
    background: #fafcff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 30px;
    p {
      line-height: 0;
    }
    .sub_title {
      font-weight: 600;
      font-size: 14px;
    }
    .texts {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
