$text: #261c4b;
$cardbackground: #ffffff;

.profile_main_conatiner {
  width: 100vw;
  display: flex;
  justify-content: center;
  .profile_inner_conatiner {
    width: 70%;
    display: flex;
    justify-content: center;
    padding: 5%;
    flex-direction: column;

    //basic info container

    .basicinfo_wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      .basicinfo_container {
        width: 100%;
        height: auto;
        position: relative;
        background: $cardbackground;
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        text-align: center;
        padding: 25px;
        .upload_profile_pic {
          display: none;
        }
        .edit_holder {
          position: absolute;
          top: 25px;
          right: 25px;
          span {
            color: #4389fa;
            margin-left: 10px;
            margin-top: 5px;
          }
        }
        .profile_image_conatiner {
          cursor: "pointer" !important;
          cursor: "pointer";
          position: absolute;
          top: -15%;
          left: 43%;
          .edit_icon {
            cursor: "pointer";
            position: absolute;
            top: 70%;
            left: 45%;
            z-index: 2;
          }
        }

        .profile_name {
          font-weight: 600;
          font-size: 30px;
          color: $text;
          margin: 10%;
          .editable_container {
            display: flex;
            .Custom_input_holder {
              text-align: left;
              margin: 0px 5px;
              .pn_label {
                font-size: 12px;
                font-weight: 600;
              }
              .invisible_input {
                border: none;
                width: 100%;
                border-bottom: 2px solid $text;
                padding: 0;
                &:focus {
                  outline: none;
                }
                &.haserror {
                  border-color: red;
                }
              }
              .error_text {
                margin: 0;
                color: red;
                font-size: 12px;
                font-weight: normal;
              }
            }
          }
        }
        .input_holders {
          display: flex;
          justify-content: space-between;
          .input_item {
            width: 45%;
          }
        }
      }
      .bottom_blue_container {
        width: 100%;
        height: auto;
        padding: 10px;
        background: #4389fa;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        text-align: center;
        img {
          cursor: pointer;
        }
      }
    }

    //   soicial media info container

    .socialInfo_wrapper {
      width: 100%;
      padding: 25px;
      background: $cardbackground;
      border-radius: 24px;
      margin-top: 50px;
      .socialInfo_heading_holder {
        width: 100%;
        font-size: 18px;
        color: $text;
        font-weight: 600;
        .info_icon {
          margin-left: 10px;
        }
      }
      .social_item_conatiner {
        width: 100%;
        .social_item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 30px;
          .custom_btn {
            width: 70px;
            font-size: 12px;
            text-align: center;
            padding: 0;
          }
          .social_item_img_holder {
            display: flex;
            align-items: center;
            img {
              margin-right: 15px;
            }
          }
        }
      }
    }

    //password info container

    .password_wrapper {
      width: 100%;
      padding: 25px;
      background: $cardbackground;
      border-radius: 24px;
      margin-top: 50px;
      .pass_title {
        width: 100%;
        font-size: 18px;
        color: $text;
        font-weight: 600;
      }
      .updated {
        color: #4389fa;
        margin-top: 25px;
        font-weight: 600;
      }
      .sub_title {
        color: #261c4b;
        margin-bottom: 25px;
      }
      .custom_btn {
        width: 200px;
        height: 40px;
        margin-top: 20px;
      }
      .change_pass_container {
        width: 100%;
        display: flex;
        .ant-form {
          width: 100%;
        }
        .input_container {
          width: 100%;
          .input_holder {
            margin: 20px 0px;
            .error_field {
              color: #ff4365;
              font-size: small;
            }
          }
        }
        .left_side_container {
          width: 50%;
          padding: 20px;
          .forgot_title {
            color: #4389fa;
            margin-top: 25px;
            font-weight: 600;
            cursor: pointer;
          }
          .describe_cont {
            display: flex;
            margin-top: 20px;
            .describe {
              color: $text;
              font-size: 12px;
              margin-top: 3px;
            }
          }
        }
      }
    }

    //subscription info container

    .subscriptions_wrapper {
      width: 100%;
      padding: 25px;
      background: $cardbackground;
      border-radius: 24px;
      margin-top: 50px;
      .subs_title {
        width: 100%;
        font-size: 18px;
        color: $text;
        font-weight: 600;
      }
      .blue_container {
        background: #ebf3ff;
        border-radius: 12px;
        color: $text;
        padding: 20px 0px 10px 20px;
        margin: 15px 0px;
        position: relative;
        .Current_Plan {
          font-size: 12px;
          color: $text;
        }
        .trial {
          font-size: 18px;
          font-weight: 600;
          color: $text;
          // span {
          //   font-size: 12px;
          //   font-weight: normal;
          // }
        }
        .custom_btn.ant-btn {
          background: white !important;
          position: absolute;
          top: 32%;
          right: 3%;
          font-size: 12px;
        }
      }
      .sub_txt {
        font-size: 14px;
        color: $text;
        margin: 15px 0px;
      }
      .buttn_container {
        margin: 15px 0px;
        display: flex;
        .custom_btn {
          width: 150px;
          // padding: 10px;
          height: 40px;
          padding: 0;
          text-align: center;
          justify-content: center;
        }
      }
    }

    //notification info container

    .notification_wrapper {
      width: 100%;
      padding: 25px;
      background: $cardbackground;
      border-radius: 24px;
      margin-top: 50px;
      .noti_title {
        width: 100%;
        font-size: 18px;
        color: $text;
        font-weight: 600;
      }
      .sub_txt {
        font-size: 14px;
        color: $text;
      }
      .switch_holder {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .Custom_switch {
          min-width: 60px;
        }
        span {
          margin-left: 10px;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .profile_main_conatiner{
    .profile_inner_conatiner{
      .basicinfo_wrapper{
        padding: 1rem;
      }
    }
  }
}