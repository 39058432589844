.skills_card_container {
  background: #ffffff;
  border: 1px solid #dee5ff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px;
  width: 32%;
  .header_title {
    font-weight: 600;
    font-size: 14px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: white;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dee5ff;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #dee5ff;
  }
  .skills_container {
    max-height: 400px;
    overflow: auto;
    padding-top: 10px;
    .skills {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      overflow: auto;
      .skill_item_active,
      .skill_item {
        border-radius: 8px;
        padding: 0 20px;
        margin-left: 10px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: normal;
        margin-top: 5px;
      }
      .skill_item_active {
        background: #ebf3ff;
      }
      .skill_item {
        background: #ffffff;
        border: 1px solid #dee5ff;
      }
    }
  }
}
