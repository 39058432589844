@import "../../styles/base.scss";

.employment-container {
  background-color: white;
  width: 100%;
  height: 100%;
  .ant-input-group-addon {
    border: none;
    padding: 0;
    background: none;
  }

  .ant-upload-list-item {
    display: none;
  }

  .ant-input-borderless,
  .ant-input-borderless:hover,
  .ant-input-borderless:focus,
  .ant-input-borderless-focused,
  .ant-input-borderless-disabled,
  .ant-input-borderless[disabled] {
    background: transparent;
    border: 1px solid $primaryFontColor;
  }

  .employment-section {
    background: linear-gradient(0deg, #ffffff, #ffffff), rgba(7, 7, 7, 0.1);
    border-radius: 24px;
    padding: 42px;
    height: inherit;
    width: 100%;
  }

  .form-section {
    width: 100%;
    background: #f4f8ff;
    border-radius: 8px;
    padding: 24px;
    margin-top: 48px;
  }

  .input-filed {
    background: transparent;
    border: 1px solid #dee5ff;
    box-sizing: border-box;
    border-radius: 8px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 10px;

    &::placeholder {
      color: black;
    }

    &:hover {
      background: transparent;
      border: 1px solid #dee5ff;
    }
  }

  .input-filed-com {
    background: transparent;
    border: 1px solid #dee5ff;
    box-sizing: border-box;
    border-radius: 8px;
    height: 60px;
    display: flex;
    align-items: center;

    &:hover {
      background: transparent;
      border: 1px solid #dee5ff;
    }
  }
  input:focus {
    outline: none;
  }

  // $media screen and (max-width: 1440px) {
  //   width: 100vw;
  // }

  // $media screen and (min-width: 1440px) {
  //   width: 1440px;
  // }

  // $media screen and (min-width: 1920px) {
  //   width: 1440px;
  // }
}
