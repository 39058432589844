.ICDP_warapper {
  width: 100%;

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
  }

  .ICDP_container {
    padding: 8% 30px;
    padding-top: 2%;
    width: 100%;

    .companySelection {
      display: flex;
      width: 100%;
      justify-content: space-between;

      .left {
        width: 70.83%;
      }

      .right {
        width: 25%;
      }
    }

    h3 {
      text-align: center;
    }

    .input_table {
      width: 40%;

      .input_row {
        display: flex;
        padding: 5px;
        justify-content: space-between;
        align-items: center;

        .input_label_col {
          font-weight: 600;
        }

        .input {
        }
      }
    }

    .confirm_company_title {
      color: #261c4b;
      font-size: 20px;
      font-weight: 600;
      padding: 15px 0px 15px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 70.83%;

      .left {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          margin-right: 10px;
        }

        .companyName {
          margin-right: 15px;
          display: flex;
          align-items: center;
        }

        span {
          color: #606060;
          font-weight: 400;
          font-size: 16px;
        }
      }
    }
    .card_header {
      background: #ffffff;
      border-radius: 16px 16px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 13px 16px;
      border-bottom: 4px solid rgb(233, 241, 255);

      .left {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .image_container {
        }

        .companyName {
          color: #261c4b;
          font-weight: 500;
          font-size: 20px;
          margin: 0px 10px;
        }

        span {
          color: #606060;
          font-weight: 400;
          font-size: 16px;
        }
      }

      .right {
        cursor: pointer;

        span {
          color: #4389fa;
          font-weight: 500;
          font-size: 16px;
          margin-left: 10px;
        }

        img {
          margin-bottom: 3px;
        }
      }
    }
    .ConfirmYourCompanyCard {
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 16px 16px;
      padding: 25px;
      width: 100%;

      .suggestions {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 15px;

        .suggestion,
        .suggestion-selected {
          border: 1px solid rgba(38, 28, 75, 0.2);
          border-radius: 100px;
          color: rgba(38, 28, 75, 0.8);
          font-weight: 500;
          font-size: 14px;
          margin: 3px;
          padding: 3px 15px;
          cursor: pointer;
        }

        .suggestion-selected {
          background: #4389fa;
          color: white;
          border: none;
        }
      }
    }

    .devider {
      border: 1px solid #e7e7e7;
      margin: 15px 0px;
    }

    .CompanyDescCard {
      background: #ffffff;
      // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
      border-radius: 0px 0px 16px 16px;
      padding: 25px;
      width: 100%;
      // margin-top: 30px;

      .card_header {
        .left {
          display: flex;

          img {
            margin-right: 10px;
            width: 50px;
            height: auto;
          }

          .companyName {
            color: #261c4b;
            font-weight: 600;
            font-size: 20px;
          }

          .location {
            color: #606060;
            font-weight: 400;
            font-size: 16px;
          }
        }
      }

      .descriptions {
        display: flex;

        .descriptions-inner {
          width: 100%;
          display: flex;
          align-items: center;

          .devider {
            height: 100%;
            border-width: 1px;
            margin-top: 15px;
          }

          .confirmmations {
            padding-left: 20px;
            width: 40%;
            margin-top: 5px;
          }
        }
      }
    }

    .CompanyNotFoundCard {
      .title {
        font-weight: 500;
        font-size: 20px;
        background: #ffffff;
        border-radius: 16px 16px 0px 0px;
        padding: 13px 25px;
        border-bottom: 4px solid rgb(233, 241, 255);
      }

      .subtitle {
        font-weight: 400;
        font-size: 16px;
        padding-bottom: 15px;
      }

      .notfoundCard {
        background: #ffffff;
        // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 16px 16px;
        padding: 25px;

        .titleCard {
          display: flex;
          font-weight: 500;
          font-size: 20px;
          margin-bottom: 20px;

          div {
            display: flex;
            align-items: center;
          }

          p {
            // background: #4389fa;
            // color: #ffffff;
            // width: 30px;
            margin: 0px;
            // border-radius: 100px;
            margin-right: 7px;
            // text-align: center;
          }
        }

        .descriptions {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          margin-bottom: 20px;
        }
      }

      .similarCompany {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .infoContainer {
          display: flex;
          align-items: center;
          width: 60%;
          background: #f9fcff;
          border-radius: 8px;
          padding: 20px;

          p {
            margin: 0px;
            margin-left: 10px;
          }
        }
      }

      .industry_input_wrapper {
        min-width: 32%;
      }
    }

    .confirmmations {
      display: flex;
      margin-top: 10px;
      font-weight: 600;

      .confirm {
      }

      .confirm,
      .checked {
        background: #ffffff;
        // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        display: flex;
        padding: 10px;
        margin: 5px;
        cursor: pointer;
        // width: 100%;

        p {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height */

          letter-spacing: 0.01em;
          font-feature-settings: "tnum" on, "lnum" on;
        }
      }

      .confirm {
        p {
          color: #606060;
        }
      }

      .checked {
        color: #4389fa;
      }

      img {
        margin-right: 5px;
        width: 18px;
        height: auto;
      }
    }

    .buttons_container {
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      //padding-top: 20px;

      .buttons_container_inner {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #606060;
        font-weight: 500;
        font-size: 16px;
        cursor: pointer;

        // .back {
        //   text-align: right;
        //   span {
        //     background: url("../../assets/images/ArrowLeft.svg") no-repeat;
        //     background-position: left;
        //     padding: 15px;
        //   }
        // }
      }
    }

    .buttons_container_with_back {
      width: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      //padding-top: 20px;

      .buttons_container_innerB {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #606060;
        font-weight: 500;
        font-size: 16px;
        width: 71%;
        margin-top: 16px;
        cursor: pointer;

        // .back {
        //   text-align: right;
        //   span {
        //     background: url("../../assets/images/ArrowLeft.svg") no-repeat;
        //     background-position: left;
        //     padding: 15px;
        //   }
        // }
      }
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    background: transparent;
  }
}

.next-disabled,
.next {
  background: url("../../assets/images/ArrowRight.svg") no-repeat;
  background-position: right;
  width: 65px;
  margin-left: 20px;
}

.next {
  &:hover {
    color: #4389fa;
    background: url("../../assets/images/nextIconBlue.svg") no-repeat;
    background-position: right;
    width: 65px;
    margin-left: 20px;
  }
}
.nxtButton {
  font-weight: 500;
  font-size: 16px;
  color: white;
  background: #4389fa;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .ICDP_warapper {
    .ICDP_container {
      .companySelection {
        flex-direction: column;

        .left {
          width: 100%;
        }

        .right {
          width: 100%;
          margin-top: 10px;
        }
      }

      .confirm_company_title {
        width: 100%;

        .left {
          padding-left: 10px;
        }
      }

      .CompanyDescCard {
        margin-top: 0px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .ICDP_warapper {
    .ICDP_container {
      .companySelection {
        flex-direction: column;

        .left {
          width: 100%;

          .ConfirmYourCompanyCard,
          .CompanyDescCard {
            padding: 15px;

            .card_header {
              .left {
                .companyName {
                  font-size: 14px;
                }
              }
            }

            .descriptions {
              .descriptions-inner {
                flex-direction: column;
                width: 100%;

                .devider {
                  display: none;
                }

                .confirmmations {
                  width: 100%;
                  padding-left: 0px;
                }
              }
            }
          }
        }

        .right {
          width: 100%;
          margin-bottom: 10px;
        }
      }

      .confirm_company_title {
        width: 100%;
        font-size: 14px;
        text-align: center;

        .left {
          flex-direction: column;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .ICDP_warapper {
    .ICDP_container {
      .confirm_company_title {
        flex-direction: column;
        text-align: left;
        align-items: baseline;

        .left {
          flex-direction: row;
          margin-top: 15px;
          padding-left: 0px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .Common_input_container {
    min-width: 49%;
  }

  .ICDP_warapper {
    .ICDP_container {
      .CompanyNotFoundCard {
        .industry_input_wrapper {
          min-width: 49%;
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  .Common_input_container {
    min-width: 100%;
    margin: 10px 0px;
  }

  .ICDP_warapper {
    .ICDP_container {
      .CompanyNotFoundCard {
        .industry_input_wrapper {
          min-width: 100%;
        }

        .similarCompany {
          flex-direction: column-reverse;

          .infoContainer {
            width: 100%;
          }
        }

        .notfoundCard {
          .titleCard {
            font-size: 14px;

            P {
              width: 23px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .Common_input_container {
    margin: 10px 0px;
  }
}
