@import "../../styles/base.scss";

.dashboard-container {
  width: 100vw;
  height: 100%;

  .new_skill_tag {
    border: 1px solid #dee5ff;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    margin: 5px 0px;
  }

  .hardSkills_containerr {
    height: 180px;
    background-color: white;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    border: 1px solid #dee5ff;
    margin: 10px 0px 0px 0px;

    h3 {
      font-weight: 700;
    }
  }

  .softSkills_containerr {
    height: 86px;
    background-color: white;
    padding: 5px 30px 5px 30px;
    border-radius: 20px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    border: 1px solid #dee5ff;
    margin: 10px 0px 0px 0px;

    h3 {
      font-weight: 700;
    }
  }

  .companyUrlTaker {
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    font-size: 16px;
    text-align: center;
    border: 1px solid #dee5ff;
  }

  .pastexpe_container {
    height: 273px;
    background-color: white;
    padding: 15px 30px 30px;
    border-radius: 20px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    border: 1px solid #dee5ff;
    margin: 20px 10px 0px 0px;

    h4 {
      font-weight: 600;
    }

    h3 {
      font-weight: 700;
    }

    p {
      margin-left: 20px;
    }
  }

  .Skill_title {
    font-weight: 600;
    font-size: 16px;
  }

  .board_container {
    height: 90%;
    overflow: hidden;
  }

  .ant-tabs {
    margin-bottom: 56px;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
    // box-shadow: 0 8px 24px 0 #e3eeff;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 0 0;
  }

  .ant-input {
    background: #ffffff;
    border: 1px solid #dee5ff;
    box-sizing: border-box;
    border-radius: 8px;
    height: 56px;
  }

  .ant-layout-header {
    padding: 0;
    background-color: white;
    display: flex;
    height: 100px;
    align-items: center;
    padding-left: 56px;
    padding-right: 56px;
    justify-content: space-between;
  }

  .ant-layout-content {
    background-color: #f9fcff;
    padding: 10px 48px 48px;
  }

  .ant-layout-header .ant-menu {
    line-height: 42px;
  }

  @media screen and (max-width: 1440px) {
    width: 100vw;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
  }

  @media screen and (min-width: 1920px) {
    width: 1440px;
  }
}

.employement_model {
  width: 100%;

  &.ant-modal {
    .ant-modal-content {
      background: white;
      border-radius: 24px;
    }

    .ant-modal-header {
      display: none;
    }

    .ant-modal-close {
      display: none;
    }
  }
}

.dashboardContainer_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .col2 {
    margin: 0px 20px 0px;
  }
}

.dashboardContainer_eachcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  cursor: pointer;
  position: relative;
  // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
  // height: 200px;
  padding: 10px 15px;
  border-radius: 10px;
  background: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  height: 200px;
  overflow: auto;
}
.dashboardContainer_eachcard.mainCard {
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  .dashboardContainer_cardLabel_Desc {
    padding-top: 0px;
    flex: unset;
    .dashboardContainer_cardbigtext {
      margin-bottom: 5px;
    }
  }
  img {
    max-width: 120px;
    margin: 0px;
  }
}

.dashboardContainer_cardLabel_Desc {
  // width: 100%;
  flex: 1;
  padding-top: 10px;
}

.dashboardContainer_dashboardcardswrap {
  padding: 0px 30px 30px;
}

.dashboardContainer_loggedinUserinfo {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 50%;
}

.desclimer {
  padding: 20px;
  background-color: white;
  position: relative;
  border-radius: 10px;
  margin-bottom: 20px;
  img {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    background: rgb(67, 137, 250);
    width: 24px;
    border-radius: 50%;
    padding: 4px;
  }
}

.dashboardContainer_loggedinUserwrap {
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  .PersonalDashboardBtn {
    button {
      background: white;
      border: none;
      border-radius: 8px;
      padding: 10px;
      color: #4389fa;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.dashboardContainer_bigtext {
  font-size: 1.8rem;
  color: #261c4b;
}

.dashboardContainer_cardbigtext {
  font-weight: 500;
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  color: #261c4b;
  margin-bottom: 1rem;
}

.dashboardContainer_userDesignation {
  font-weight: 400;
  font-size: 18px;
  // line-height: 27px;
  letter-spacing: 0.01em;
  color: #261c4b;
}

.dashboardContainer_cardsubtext {
  font-size: 1rem;
  color: #606060;
}

@media screen and (max-width: 600px) {
  .dashboardContainer_container {
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }

  .dashboardContainer_eachcard {
    height: auto;
    padding: 2rem 1rem;
  }

  .dashboardContainer_dashboardcardswrap {
    padding: 0 24px;
    margin-bottom: 2rem;
  }

  .dashboardContainer_loggedinUserinfo {
    width: 100%;
  }

  .dashboardContainer_bigtext {
    font-size: 20px;
    font-weight: 500;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }

  .dashboardContainer_cardImage {
    width: 56px;
    height: 56px;
  }

  .dashboardContainer_cardbigtext {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .dashboardContainer_cardsubtext {
    font-size: 0.8rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }

  .dashboardContainer_userDesignation {
    font-weight: 400;
    font-size: 14px;
    opacity: 0.54;
  }
}

@media screen and (max-width: 900px) {
  .dashboardContainer_container {
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    padding: 1rem;
    .col2 {
      margin: 0;
    }
  }

  .dashboardContainer_eachcard {
    height: auto;
  }

  .dashboardContainer_loggedinUserwrap {
    justify-content: start;
    align-items: end;
    flex-direction: column;
    padding-bottom: 15px;
    .dashboardContainer_loggedinUserinfo {
      margin-bottom: 10px;
      width: 100%;
    }
  }
  .dashboardContainer_cardImage {
    width: 76px;
    height: 76px;
  }
  .dashboardContainer_dashboardcardswrap {
    padding: 5px 0rem;
  }
}

@media screen and (max-width: 400px) {
  .dashboardContainer_loggedinUserwrap {
    .dashboardContainer_loggedinUserinfo {
      .dashboardContainer_bigtext {
        font-size: 16px;
      }
    }
    .PersonalDashboardBtn {
      button {
        font-size: 12px;
      }
    }
  }
}
