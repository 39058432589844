.LinkPreviewCard {
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  min-width: 270px;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.06); */
}
.readMoreBtn {
  background: #ffffff;
  border: 1px solid #4389fa;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
  color: #4389fa;
  cursor: pointer;
}
.epNpdM {
  display: flex;
  flex-direction: column;
  /* border-radius: 10px; */
  max-width: none !important;
  background-color: #fff !important;
  border-width: none !important;
  border-style: none !important;
  border-color: none !important;
  color: #181919 !important;
  display: flex !important;
  -webkit-text-decoration: none !important;
  -webkit-transition-duration: none !important;
  transition-duration: none !important;
  -webkit-transition-timing-function: none !important;
  transition-timing-function: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  will-change: none !important;
}
.eZAbUf {
  flex: 0 0 185px !important;
  /* border-radius: 10px; */
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}
.jJrGOv {
  padding: 20px 0px 15px 0px !important;
}
header > .gXMXyK.gXMXyK.gXMXyK {
  font-size: 16px;
  margin-bottom: 0px !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: inherit !important;
  height: 76px !important;
}
.eohlsP.eohlsP.eohlsP {
  font-size: 14px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: inherit !important;
  height: 36px !important;
}
footer > .gXMXyK.gXMXyK.gXMXyK {
  font-size: 14px;
  color: #4389fa !important;
}
.fWzjRp {
  display: none !important;
}
