@import "../../styles/base.scss";

.forgotpass-container {
  background-color: $backgroundColor;
  width: 100%;
  height: 100vh;
  position: relative;

  .forgot_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #4389fa;
  }

  .desctext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #606060;
    margin-bottom: 16px;
  }

  .backtext {
    width: 100%;
    text-align: center;
    margin-top: 40px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #4389fa;
    cursor: pointer;
  }

  .person-img_login {
    position: absolute;
    bottom: 0;
    right: 13%;
    z-index: 1;
    width: 25%;
    height: auto;
  }
  .footer_login {
    background: $primaryColor;
    height: 40px;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .right_side {
    width: 45%;
    .tryFree_button {
      border: 1px solid #4389fa;
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      padding: 10px 25px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: 47%;
      letter-spacing: 0.03em;
      cursor: pointer;
      color: #4389fa;
    }
    .subscrption_btn {
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      padding: 10px 25px;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      width: 47%;
      letter-spacing: 0.03em;
      cursor: pointer;
      color: #4389fa;
      border: none;
    }
  }

  .login-section {
    background: linear-gradient(0deg, #ffffff, #ffffff), rgba(7, 7, 7, 0.1);
    border-radius: 24px;
    padding: 30px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    width: 45%;
    input {
      background: white;
      border: 1px solid $inputFieldBorder;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      height: 50px;
      padding-left: 15px;
      &:hover {
        background: white;
        border: 1px solid $inputFieldBorder;
      }
      &:focus {
        outline: none;
      }
    }
    .error_fields {
      color: #ff4d4f;
      height: 22px;
    }
    .hint-text {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 200%;
      color: $primaryFontColor;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        margin: 0%;
      }
    }
    .login_btn {
      height: 48px;
      cursor: pointer;
      background: rgb(67, 137, 250);
      border-radius: 8px;
      color: rgb(255, 255, 255);
      border: none;
      font-weight: 600;
      width: 100%;
      margin-top: 50px;
    }
  }
}
