.onboardingHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 15px;
  margin-bottom:2.5rem;
  .step {
    display: flex;
    align-items: center;
  }
  .numbercircle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #4389fa;
  }
  .numbertext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #ffffff;
    margin: 0;
  }

  .addResumeText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-feature-settings: "tnum" on, "lnum" on;
    color: #4389fa;
    margin-bottom: 0;
    margin-left: 8px;
  }

  .line {
    width: 146px;
    border-bottom: 2px solid #d5e0f3;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 850px) {
  .onboardingHeader {
    margin-bottom: 1rem;
    .step {
      padding: 0px 10px;
    }
    .line {
      display: none;
    }
  }
}
@media only screen and (max-width: 600px) {
  .onboardingHeader {
    align-items: baseline;
    .step {
      flex-direction: column;
      .numbertext {
        font-size: 14px;
      }
      .addResumeText {
        font-size: 11px;
        line-height: 1.2rem;
      }
    }
    .line {
      display: none;
    }
  }
}
