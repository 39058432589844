@import "../styles/base.scss";

.primarybtn {
  height: "48px";
  background: "#4389fa";
  border-radius: "8px";
  opacity: "0.5";
  color: "#ffffff";
}

.basebtn {
  height: "48px";
  background: "#4389fa";
  border-radius: "8px";
  color: "#ffffff";
}

//  *******Header Styles********

.header-style {
  display: flex;
  justify-content: space-between;
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-bottom: 1px solid $primaryFontColor;
    background-color: transparent;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    box-shadow: none;
    border-bottom: 1px solid $primaryFontColor;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 4px;
  }

  .ant-select-selection-placeholder {
    color: $primaryFontColor;
    opacity: 70%;
  }

  .ant-menu-horizontal {
    background: none;
    background-color: transparent;
    border: none;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    margin: 0;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border: none;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
    border: none;
  }

  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
    background-color: #f4f8ff;
    border-radius: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .mined-title {
    color: $primaryFontColor;
    font-size: 32px;
    margin-bottom: 0;
  }

  .logo {
    width: 90px;
    height: 50px;
    background: white;
    background-color: white;
  }

  .profile-style {
    display: flex;
    width: auto;
    align-items: center;
  }

  .primary-fonttext {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #4389fa;
    cursor: pointer;
    margin-left: 24px;
    margin-top: 8px;
  }
}

.popover_container {
  min-width: 380px;
  pad: 5px;
}
.list_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  white-space: break-spaces;
  width: 100%;
  margin-bottom: 10px;
  .icon_custom {
    padding: 0px 10px;
  }
  .popover_txt {
    h3 {
      font-weight: 600;
      font-size: 16px;
      line-height: normal;
    }
    div {
      line-height: normal;
      font-weight: 300;
      font-size: 12px;
    }
  }
  &:hover {
    h3 {
      color: #5998fb;
    }
  }
}
.profile_list_item {
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background-color: #f4f8ff;
  }
}
.proppopover_container {
  cursor: pointer;
}

.tool_menu {
  position: absolute;
  top: 10px;
  right: -120px;
  z-index: 10;
}
.box {
  width: 70%;
  background-color: #ffffff;
  border-radius: 24px;
  color: black;
  padding: 20px;
  position: relative;
  margin: 40px;
  // box-shadow: 0 8px 32px 0 #e3eeff;
}
.box.arrow-top {
  margin-top: 40px;
}

.box.arrow-top:after {
  content: " ";
  position: absolute;
  right: 30px;
  top: -15px;
  border-top: none;
  border-bottom: 15px solid #ffffff;
  border-left: 15px solid transparent;
}
.profile_menu {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  .box {
    width: 90%;
  }
}
