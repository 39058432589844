@import "../../styles/base.scss";

.registrationLastScreenContainer {
  background-color: rgb(233, 241, 255);
  color: rgba(0, 0, 0, 0.85);
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 30px 60px;

  .lastStepSubDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 75px;
    padding-left: 75px;
    padding-bottom: 24px;
  }

  .centerCard {
    width: 100%;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    margin-top: 40px;
    margin-bottom: 16px;
    cursor: pointer;
    padding: 0px 0px 24px 0px;

    .antGroupDiv{
      margin-bottom: 20px;
      margin-left: 75px;
      text-align: center;
      display: flex;
    }

    .antGroupSubDiv{
      padding: 16px 75px;
    }

    .cardTitle {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #261c4b;
      border-bottom: 4px solid rgb(233, 241, 255);
      padding: 14px 75px;
    }

    .bottomtext {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #606060;
      margin-bottom: 0;
    }

    .cardSubtitle {
      font-family: "Poppins";
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.01em;
      font-feature-settings: "tnum" on, "lnum" on;
      color: #261c4b;
      margin-bottom: 24px;
      margin-left: 75px;
      padding-top: 32px;
      font-weight: 600;
    }

    .selectedSubDiv {
      display: flex;
      min-height: 54px;
      justify-content: center;
      align-items: center;
      background: #4389fa;
      border: 1px solid #4389fa;
      border-radius: 8px;

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #ffffff;
        margin: 0;
        text-align: center;
        padding-left: 18px;
        padding-right: 18px;
      }
    }

    .subdivs {
      display: flex;
      min-height: 54px;
      justify-content: center;
      align-items: center;
      background: white;
      border: 1px solid rgba(67, 137, 250, 0.38);
      border-radius: 8px;

      p {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        font-feature-settings: "tnum" on, "lnum" on;
        color: #261c4b;
        margin: 0;
        text-align: center;
        padding-left: 18px;
        padding-right: 18px;
      }
    }
  }
  .suggestionWrapper {
    width: 25%;
    padding-top: 40px;
  }
}

.back {
  margin-bottom: 20px;
  span {
    background: url("../../assets/images/ArrowLeft.svg") no-repeat;
    background-position: left;
    padding: 15px;
  }
  &:hover {
    color: #4389fa;
  }
}

/* Styles for phones (up to 767px) */
@media only screen and (max-width: 767px) {
  .atsViewModal{
    width: 80vw !important;
  }
  
  .atsViewModal.ant-modal .ant-modal-content {
    padding: 16px;
  }
  
  .atsViewModal.ant-modal .ant-modal-body{
    padding: 1px;
  }

  .registrationLastScreenContainer .centerCard .cardSubtitle{
      font-size: 14px;
      margin-bottom: 24px;
      margin-left: 8px;
      padding-top: 24px;
  }

  .registrationLastScreenContainer .centerCard .antGroupDiv{
    margin-bottom: 20px;
    margin-left: 12px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
  }

  .registrationLastScreenContainer .centerCard .antGroupSubDiv{
    padding: 8px 12px;
  }
  
}
