@import "../../styles/base.scss";

.tools-container {
  width: 100vw;
  height: 100%;

  .ant-layout-header {
    padding: 0;
    background-color: white;
    display: flex;
    height: 100px;
    align-items: center;
    padding-left: 56px;
    padding-right: 56px;
    justify-content: space-between;
  }
  .ant-layout-header .ant-menu {
    line-height: 42px;
  }

  .tools_navBar {
    width: 100%;
    padding: 48px;
    margin-left: 20px;
    .navItem {
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: left;
      .back {
        font-weight: normal;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
        display: flex;
        align-items: center;
        .back_icon {
          font-size: 10px;
        }
      }
      .divider {
        padding: 0px 5px;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    width: 100vw;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
  }

  @media screen and (min-width: 1920px) {
    width: 1440px;
  }
}
