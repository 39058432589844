$cardbackground: #ffffff;

.basicinfo_wrapper_section {
  width: 100%;
  display: flex;
  flex-direction: column;

  .basicinfo_container_section {
    width: 90%;
    margin: auto;
    height: auto;
    position: relative;
    background: $cardbackground;
    border-radius: 24px;
    padding: 25px;
    // box-shadow: 0 8px 32px 0 #e3eeff;
    .avatar_logo {
      position: absolute;
      right: 19px;
      top: -81px;
      width: 205px;
      height: 141px;
    }
    .header_container {
      display: flex;
      width: 100%;
      align-items: center;
      .header_text {
        margin-left: 15px;
      }
    }
    .body_section {
      padding: 15px;
      padding-top: 20px;
      .error_field {
        color: crimson;
      }
      .input_holders_section {
        display: flex;
        justify-content: space-between;
        width: 100%;

        .item_input {
          width: 100%;
          margin-right: 10px;
          margin-left: 10px;
        }
      }
      .text_area_section {
        padding: 20px 10px 0px;
        .text_item.ant-input {
          border-radius: 8px;
          border: 1px solid #dee5ff;
        }
      }
      .button_submit {
        height: 40px;
        margin-top: 20px;
        margin-left: 10px;
        width: 150px;
      }
      .container_form_header {
        margin-bottom: 20px;
        margin-left: 10px;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}
