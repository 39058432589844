.headerSelector.ant-select {
  border: none;
  color: white;
  .ant-select-selector {
    background: transparent !important;
    border: none !important;
    color: white !important;
  }
}
.ant-dropdown-menu-submenu-expand-icon {
  display: none;
}
.ant-dropdown-menu.ant-dropdown-menu-sub.ant-dropdown-menu-vertical {
  border-radius: 10px;
}

.newHeaderSubDiv {
  max-width: 1420px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px;
  width: 100%;
}

.unlockImg {
  width: 142px;
  height: auto;
  cursor: pointer;
}

.dividerIcon {
  width: 5px; 
  margin: 0px 10px;
  height: 20px;
}

.headerHomeIconDiv {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: white;
  margin-right: 20px;
}

.dropDownDiv {
  color: white;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
}

.details_btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #347bee;
  border-radius: 8px;
  padding: 12px;
  margin-right: 32px;

  img {
    width: 24px;
    height: 24px;
  }
  .desctxt {
    width: 266px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin: 0px 10px;
  }
  .moewDetailsBtn {
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.01em;
    color: #4389fa;
    margin: 0;
    padding: 8px 16px;
    cursor: pointer;
    word-break: keep-all;
    white-space: nowrap;
  }
}

.logohide{
  display: none;
}


@media screen and (max-width:800px) {
  .details_btn{
    display: none;
  }
}

/* Styles for phones (up to 767px) */
@media only screen and (max-width: 767px) {

  .newHeaderSubDiv {
    padding: 12px 16px;
  }
  
  .unlockImg {
    width: 90px;
    height: auto;
    cursor: pointer;
  }

  .headerHomeIconDiv {
    margin-right: 8px;
  }

  .dividerIcon{
    margin: 0 4px;
  }

  .dropDownDiv {
    color: white;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }
}

/* Styles for tablets (768px to 1023px) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {

  .newHeaderSubDiv {
    padding: 12px 16px;
  }
  
  .unlockImg {
    width: 90px;
    height: auto;
    cursor: pointer;
  }

  .headerHomeIconDiv {
    margin-right: 8px;
  }

  .dividerIcon{
    margin: 0 4px;
  }

  .dropDownDiv {
    color: white;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
  }
}
