.offerEvaluatorResultContainer {
  width: 100%;
  height: 100%;

  .headertxt {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #261c4b;
    margin-left: 10px;
  }

  .subheader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    // box-shadow: 0px 2px 45px rgba(0, 0, 0, 0.05);
    border-radius: 24px;
    padding: 32px;
    margin-bottom: 24px;

    p {
      margin: 0;
      padding: 0;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.01em;
      color: #261c4b;
    }
  }
}

.ratingdiv {
  display: flex;

  .circle {
    width: 12px;
    height: 12px;
    background: rgba(0, 153, 0, 0.2);
    border-radius: 50%;
    margin-right: 6px;
  }
}
